












import {
  defineComponent,
  ref,
  onMounted,
} from "@nuxtjs/composition-api";
import { useContent } from "~/composables";
import type { CmsBlock } from "~/modules/GraphQL/types";
import ContentBlock from "./ContentBlock.vue";

export default defineComponent({
  name: "AboutUsHomePageSection",
  components: {
    ContentBlock,
  },
  setup() {
    const { loadBlocks } = useContent();
    const blocks = ref<CmsBlock[]>([]);

    onMounted(async () => {
      blocks.value = await loadBlocks({ identifiers: ['about-us-home-page-section'] });
    });

    return {
      blocks,
    };
  },
});
